import React from 'react'
import PartnershipItem from './PartnershipItem'
import { PartnershipRowProps, PartnershipItemProps } from '~types'
import Heading from '../Heading/Heading'
import styles from './PartnershipRow.module.scss'

const PartnershipRow = ({ align = 'left', partnershipRowHeadline = '', partnershipRow = {} }: PartnershipRowProps) => {
  return (
    <>
      <div className={styles['partnership-icons']}>
        {partnershipRowHeadline ? (
          <Heading level={6} justify={align}>
            {partnershipRowHeadline}
          </Heading>
        ) : null}

        <ul className={styles[align == 'center' ? 'is-aligned-center' : '']}>
          {partnershipRow.length &&
            partnershipRow.map(
              ({ icon, platformName, url, newtab }: PartnershipItemProps, navIndex: number) =>
                url && (
                  <li key={navIndex}>
                    <PartnershipItem icon={icon} platformName={platformName} url={url} newtab={newtab} />
                  </li>
                )
            )}
        </ul>
      </div>
    </>
  )
}

export default PartnershipRow
