import React from 'react'
import { analyticsClickHandler } from '~analyticsUtils'
import Image from 'next/image'
import Link from 'next/link'
import { PartnershipItemProps } from '~types'
import styles from './PartnershipRow.module.scss'
import { useRouter } from 'next/router'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { ANALYTICS_LINK_LOCATION_FOOTER, CTA_CLICK_EVENT } from '~analyticsConstants'

const PartnershipItem = ({ icon, platformName, url }: PartnershipItemProps) => {
  const { asPath, query } = useRouter()

  return (
    <div className={styles['partnership-item']}>
      <Link
        href={url}
        target={'_blank'}
        onClick={() => {
          analyticsClickHandler(platformName, asPath)
          gaBtnClick(CTA_CLICK_EVENT, ANALYTICS_LINK_LOCATION_FOOTER, platformName)
        }}
      >
        <Image width={36} height={36} sizes="100vw" alt={platformName} src={icon?.url} loading="lazy" />
      </Link>
    </div>
  )
}

export default PartnershipItem
