import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { SeoProps } from '~types'
import { isProd } from '~constants'

const SEO = ({
  allowSearchEnginesToShowThisPage,
  defaultTitle,
  ogImage,
  metaDescription,
  seoTitle,
  shouldSearchEnginesFollowLinks,
  ogUrl,
  ogTitle,
  ogType,
  ogDescription
}: SeoProps) => {
  const [pageUrl, setPageUrl] = useState('/')

  useEffect(() => {
    setPageUrl(window.location.href)
  })

  const pageTitle = seoTitle || `${defaultTitle} | Sphere`

  const robotsValues = []

  const robotsIndex = allowSearchEnginesToShowThisPage && isProd ? 'index' : 'noindex'
  robotsValues.push(robotsIndex)

  const robotsFollow = shouldSearchEnginesFollowLinks && isProd ? 'follow' : 'nofollow'
  robotsValues.push(robotsFollow)

  return (
    <Head>
      <title>{pageTitle}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      <meta name="robots" content={robotsValues.join(', ')} />
      <link rel="canonical" href={pageUrl} />
      <meta property="og:url" content={ogUrl || pageUrl} />
      <meta property="og:type" content={ogType || 'website'} />
      <meta property="og:title" content={ogTitle || pageTitle} />
      <meta property="og:description" content={ogDescription || 'Shows and concerts at Sphere Las Vegas. An experience like no other.'} />
      {ogImage && ogImage.url && <meta property="og:image" content={ogImage.url} />}
    </Head>
  )
}

export default SEO
