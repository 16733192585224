import router from 'next/router'
import { Heading, PortalModal, Form, Button, Paragraph } from '~elements'
import styles from './PremiumCard.module.scss'
import { ButtonProps } from '~types'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { useState, useEffect } from 'react'
import cx from 'classnames'
import { constructS3Url } from 'utils'

interface PremiumCardProps {
  imageRedirectUrl?: string
  buttonGroup?: Array<ButtonProps>
  headline?: string
  eyebrow?: string
  subheadline?: string
  description?: { html: string }
  backgroundGradient: string
  backgroundColor?: { hex: string }
  eyebrowColor: string
  descriptionIsCenterAligned?: boolean
  eyebrowIsCenterAligned?: boolean
  headlineIsCenterAligned?: boolean
  buttonIsCenterAligned?: boolean
  media?: {
    id: string
    image: {
      url: string
    }
    video: [
      {
        url: string
      }
    ]
    componentName: string
    imageUrlS3: string
    posterImage: [
      {
        url: string
      }
    ]
    posterImageUrlS3: string
  }
}

const PremiumCard = ({
  buttonGroup,
  headline,
  eyebrow,
  description,
  imageRedirectUrl,
  backgroundGradient,
  backgroundColor,
  eyebrowColor,
  descriptionIsCenterAligned,
  eyebrowIsCenterAligned,
  headlineIsCenterAligned,
  buttonIsCenterAligned,
  media
}: PremiumCardProps) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [modalFormId, setModalFormId] = useState('')
  const buttonContainerStyles = cx([styles['btn-container'], styles[buttonIsCenterAligned ? 'center-aligned' : '']])

  const handleButtonClick = ({ modalFormId, title, url, displayFevoWidget }: ButtonProps) => {
    if (modalFormId) {
      setShowSignUpModal(true)
      setModalFormId(modalFormId)
    } else if (url && !displayFevoWidget) {
      router.push(url)
    } else {
      gaBtnClick(CTA_CLICK_EVENT, headline || '', title)
    }
  }

  const closeModalHandler = () => {
    setShowSignUpModal(false)
    setModalFormId('')
  }

  const formSubmitHandler = () => {
    setShowSignUpModal(false)
    setModalFormId('')
  }
  const [backgroundStyle, setBackgroundStyle] = useState()
  const getBackgroundGradientStyle = (gradient: string, color = '#003C4E') => {
    switch (gradient) {
      case 'radial_gradient':
        return `radial-gradient(78.47% 68.23% at 100% 100%, rgba(255, 255, 255, 0.15) 0%, ${color} 100%)`
      case 'linear_gradient':
        return `linear-gradient(173.92deg, #000000 13.09%, ${color} 96.05%)`
      case 'transparent':
        return 'transparent'
    }
  }

  useEffect(() => {
    const newStyle = getBackgroundGradientStyle(backgroundGradient, backgroundColor?.hex)
    setBackgroundStyle(newStyle)
  }, [backgroundGradient, backgroundColor])

  return (
    <>
      <div className={styles['premium-card']}>
        <div className={styles['image-container']} style={{ cursor: imageRedirectUrl ? 'pointer' : 'default' }}>
          <div className={styles['media-wraper']}>
            {media && (
              <div className={styles['media-container']} key={media.id}>
                {media.componentName === 'Image' && media.imageUrlS3 ? (
                  <>
                    <div className={styles['media-image']} style={{ backgroundImage: `url("${constructS3Url(media?.imageUrlS3)}")` }} />
                    <div className={styles['image-overlay']} />
                  </>
                ) : (
                  <div className={styles['video-container']}>
                    <video
                      id="background-video"
                      loop
                      muted
                      autoPlay={true}
                      width={'100%'}
                      poster={media.posterImageUrlS3}
                      disableRemotePlayback
                      controls={false}
                      playsInline={true}
                    >
                      <source src={media.video[0].url} type="video/mp4" />
                    </video>
                    <div className={styles['media-overlay']} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={`${styles['gradient-block']} ${styles[backgroundGradient]} `} style={{ background: backgroundStyle }}>
          <div className={styles['premium-card-content']}>
            <div className={styles['premium-card-content__inner']}>
              {headline && (
                <Heading level={4} justify={headlineIsCenterAligned ? 'center' : 'left'}>
                  {headline}
                </Heading>
              )}
              {eyebrow && (
                <Heading level={6} levelDisplay={'eyebrow'} color={eyebrowColor} justify={eyebrowIsCenterAligned ? 'center' : 'left'}>
                  {eyebrow}
                </Heading>
              )}
              {description?.html && <Paragraph text={description} justify={descriptionIsCenterAligned ? 'center' : 'left'} />}
            </div>
          </div>

          {buttonGroup && buttonGroup.length > 0 && (
            <div className={buttonContainerStyles}>
              {buttonGroup.map((buttonProps, index) => (
                <Button key={`${buttonProps.title}_${index}`} {...buttonProps} onClick={() => handleButtonClick(buttonProps)} />
              ))}
            </div>
          )}
        </div>
      </div>
      <PortalModal isOpen={showSignUpModal} handleModalClose={closeModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} formSubmitHandler={formSubmitHandler} isChildOfModal />
      </PortalModal>
    </>
  )
}

export default PremiumCard
